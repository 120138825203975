let infoGrafik = [
  {
    name: 'Minggu',
    value : 'Week'

  },
  {
    name: 'Bulan',
    value : 'Month'
  },
  {
    name: 'Tahun',
    value : 'Year'
  }
]
export default infoGrafik;
